@import "./variables.scss";

@import "blocks/sd-element.scss";
@import "blocks/sd-question.scss";
@import "blocks/sd-input.scss";
@import "blocks/sd-comment.scss";
@import "blocks/sd-panel.scss";
@import "blocks/sd-signaturepad.scss";
@import "blocks/sd-checkbox.scss";
@import "blocks/sd-matrixdynamic.scss";
@import "blocks/sd-table.scss";
@import "blocks/sd-page.scss";
@import "blocks/sd-row.scss";
@import "blocks/sd-title.scss";
@import "blocks/sd-description.scss";
@import "blocks/sd-item.scss";
@import "blocks/sd-selectbase.scss";
@import "blocks/sd-checkbox.scss";
@import "blocks/sd-radio.scss";
@import "blocks/sd-visuallyhidden.scss";
@import "blocks/sd-matrix.scss";
@import "blocks/sd-rating.scss";
@import "blocks/sd-ranking.scss";
@import "blocks/sd-dropdown.scss";
@import "blocks/sd-imagepicker.scss";
@import "blocks/sd-image.scss";
@import "blocks/sd-html.scss";
@import "blocks/sd-progress.scss";
@import "blocks/sd-boolean.scss";
@import "blocks/sd-paneldynamic.scss";
@import "blocks/sd-file.scss";
@import "blocks/sd-hidden.scss";
@import "blocks/sd-navigation.scss";
@import "blocks/sd-button.scss";
@import "blocks/sd-body.scss";
@import "blocks/sd-multipletext.scss";
@import "blocks/sd-action.scss";
@import "blocks/sd-action-bar.scss";
@import "blocks/sd-context-btn.scss";
@import "blocks/sd-completedpage.scss";
@import "blocks/sd-progress-buttons.scss";
@import "../common-styles/sv-actionbar.scss";
@import "../common-styles/sv-popup.scss";
@import "../common-styles/sv-list.scss";
@import "../common-styles/sv-buttongroup.scss";
@import "../common-styles/sv-visuallyhidden.scss";
@import "../common-styles/sv-hidden.scss";
@import "../common-styles/sv-titleactions.scss";
@import "../common-styles/window.scss";
@import "../signaturepad.scss";
@import "./defaultV2.m600.scss";

body {
  --sv-defaultV2-mark: true;
}

.sd-root-modern {
  --sd-mobile-width: 600px;
  --sd-imagepicker-gap: 16px;
  width: 100%;
  font-family: $font-family;
  background-color: $background-dim;
  * {
    scrollbar-width: thin;
    scrollbar-color: $border transparent;
  }
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 32px;
    background-color: $border;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    border: 2px solid rgba(0, 0, 0, 0);
    background-color: $foreground-light;
  }
}
.sv-popup {
  .sv-popup__scrolling-content {
    scrollbar-width: thin;
    scrollbar-color: $border transparent;

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: $background;
    }
    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 32px;
      background-color: $border;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
      border: 2px solid rgba(0, 0, 0, 0);
      background-color: $foreground-light;
    }
  }
}

.sv-popup.sv-popup--dropdown {
  .sv-popup__shadow {
    filter: drop-shadow(0px calcSize(1) calcSize(2) rgba(0, 0, 0, 0.1));
  }
  .sv-popup__body-content {
    background-color: $background;
    padding: calcSize(1) 0;
  }
}

.sv-popup.sv-popup--modal {
  .sv-popup__body-content {
    box-shadow: 0px calcSize(1) calcSize(2) $shadow-medium;
    background-color: $background-dim-light;
  }
  .sv-popup__body-footer {
    padding-bottom: 2px;
  }
}

.sv-popup.sv-popup--overlay {
  .sv-popup__body-content {
    background-color: $background-dim;
  }
}

.sv-list__input:focus {
  border: 1px solid transparent;
}
