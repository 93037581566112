.sd-context-btn {
  background-color: $background;
  padding: calcSize(1.5);
  border-radius: calcSize(12.5);
  border: none;
  outline: none;
  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
  use {
    fill: $foreground-light;
  }
  cursor: pointer;
}
.sd-context-btn:hover,
.sd-context-btn:focus {
  use {
    fill: $primary;
  }
  &.sd-context-btn--negative {
    use {
      fill: $red;
    }
  }
}
.sd-context-btn:disabled {
  opacity: 0.25;
}
