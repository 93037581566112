.sd-body {
  width: 100%;

  .sd-body__page {
    min-width: calc(300px + 6 * #{$base-unit});
  }
  .sd-body__timer {
    padding: calcSize(2) calcSize(2) 0;
    box-sizing: border-box;
  }
  &.sd-body--static {
    .sd-body__timer,
    .sd-body__navigation,
    .sd-body__page {
      margin-left: auto;
      margin-right: auto;
      max-width: calcSize(84);
    }
  }
  &.sd-body--responsive {
    max-width: initial;
    .sd-body__timer,
    .sd-body__navigation {
      padding: calcSize(2) calcSize(3);
    }
  }
}
.sd-body__navigation {
  box-sizing: border-box;
  padding: calcSize(3) calcSize(2);
  .sv-action:not(.sv-action--hidden) ~ .sv-action:not(.sv-action--hidden) {
    margin-left: calcSize(2);
  }
}
.sd-body__progress {
  margin-bottom: calcSize(4);
}
