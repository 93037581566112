.sd-panel__title {
  outline: none;
}
.sd-panel {
  position: relative;
  .sd-row {
    margin-top: calcSize(1);
  }
  .sd-row:first-child {
    margin-top: 0;
  }
}
.sd-panel__required-text {
  color: $red;
}
