@import "./fonts.scss";

$primary: var(--primary, #19b394);
$primary-light: var(--primary-light, rgba(25, 179, 148, 0.1));
$primary-foreground: var(--primary-foreground, #fff);
$primary-foreground-disabled: var(--primary-foreground-disabled, rgba(#fff, 0.25)); 

$secondary: var(--secondary, #ff9814);
$secondary-light: var(--secondary-light, rgba(255, 152, 20, 0.25));

$secondary-foreground: var(--secondary-foreground, #fff);

$background: var(--background, #fff);
$background-dim: var(--background-dim, #f3f3f3);
$background-dim-light: var(--background-dim-light, #f9f9f9);
$background-for-editors: $background-dim-light;

$foreground: var(--foreground, #161616);
$foreground-light: var(--foreground-light, #909090);
//todo: discuss this variable
$foreground-disabled: var(--foreground-disabled, rgba(#161616, 0.16));
$foreground-disabled-for-editors: var(--foreground-disabled, rgba(#161616, 0.25));

$border: var(--border, #d6d6d6);
$border-light: var(--border-light, #eaeaea);
$border-inside: var(--border-inside, rgba(0, 0, 0, 0.16));

$shadow-medium: rgba(0, 0, 0, 0.1);
$shadow-inner: rgba(0, 0, 0, 0.15);

$red: var(--red, #e60a3e);
$red-light: var(--red-light, rgba(230, 10, 62, 0.1));
$green: var(--green, #19b394);
$green-light: var(--green-light, rgba(25, 179, 148, 0.1));
$blue-light: var(--blue-light, rgba(67, 127, 217, 0.1));

$font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

$base-unit: var(--base-unit, 8px);

@function calcSize($multiplier) {
  $result: calc(#{$multiplier} * #{$base-unit});
  @return $result;
}

@mixin smallBold {
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: calcSize(1.5);
  line-height: calcSize(2);
}

@mixin defaultBold {
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: calcSize(2);
  line-height: calcSize(3);
}

@mixin defaultFont {
  font-family: $font-family;
  font-style: normal;
  font-size: calcSize(2);
  line-height: calcSize(3);
}
