body {
  height: 100%;
  overflow-x: auto;
}

body {
  background: white;
  font-family: apercu-regular, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  overscroll-behavior-y: contain;
  overflow: auto;
}

@media (min-width: 650px) {
  body {
    background: #033;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#dimmerlite {
  -webkit-overflow-scrolling: touch;
  user-select: none;
}

#dimmerlite > div,
header {
  -webkit-transform: translate3d(0, 0, 0);
}

#font-picker {
  width: 100% !important;
}

:root {
  --toolbar-background: #339999;
  --toolbar-color: rgba(255, 255, 255, 0.9);
  --player-background: #ffdebf;
  --player-color: rgba(0, 0, 0, 0.7);
  --text-color: rgba(0, 0, 0, 0.8);
  --bold-color: #ffffff;
  --done-background: #eeffee;
  --active-background: #ffeeee;
  --pending-background: #ffffee;
  --list-main-color: #000000;
  --list-secondary-color: #808080;
  --icon-color: rgba(0, 0, 0, 0.74);
  --icon-shadow: rgba(255, 255, 255, 0.74);
  --black-icon-color: rgba(0, 0, 0, 0.8);
  --black-icon-shadow: rgba(0, 0, 0, 0.7);

  --toolbar-background-bg: #ffdebf;
  --theme-color: #ff7c00;
  --toolbar-background-dark: #ed7700;
  --toolbar-background-light: #ffdebf;
  --theme-disabled: #d4d4d4;
  --line-color: #b2b2b2;
  --icon-color: #898989;
  --secondary-color: #898989;
  --table-color: #e9e9e9;
  --secondary-disabled: #bebebe;
  --secondary-hover: #5e5e5e;
  --header-color: #fafafa;
  --second-avatar: #b23b3b;
  --first-avatar: #618bbb;
  --line-grey: #dddddd;
  --green: #4caf50;
  --once-icon: #00bcd4;

  --green-light: #4caf5052;
  --font-normal: apercu-regular, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --font-medium: apercu-medium, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --font-bold: apercu-bold, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

@media only screen and (min-width: 768px) {
  div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(77, 77, 77, 0.8);
    background-color: #f5f5f5;
  }

  div::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  div::-webkit-scrollbar-thumb {
    background-color: var(--toolbar-background);
    border: 1px solid #cccccc;
  }
}

@keyframes bubbleGrow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.cbubble {
  /* This section calls the slideInFromLeft animation we defined above */
  animation: 0.3s ease-in 0s 1 bubbleGrow;
}

.tractive {
  text-align: right;
}

#mainFrame {
  transition: opacity 0.15s, z-index 0s, display 0s;
}

#mainFrame.mffullhidden {
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
  transition: opacity 0.15s, z-index 0s 0.15s, display 0s 0.15s;
  display: none;
}

#bookFrame.mffullhidden {
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
  transition: opacity 0.15s, z-index 0s 0.15s, display 0s 0.15s;
  display: none;
}

#bookFrame {
  transition: opacity 0.15s, z-index 0s, display 0s;
}

#bookFrame.mfhidden {
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
  transition: opacity 0.15s, z-index 0s 0.15s;
}

#condensedFrame.mffullhidden {
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
  transition: opacity 0.15s, z-index 0s 0.15s, display 0s 0.15s;
  display: none;
}

#condensedFrame {
  transition: opacity 0.15s, z-index 0s, display 0s;
}

#condensedFrame.mfhidden {
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
  transition: opacity 0.15s, z-index 0s 0.15s;
}

#dimmerlite.mfhidden {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s, z-index 0s 0.15s;
}

#dimmerlite {
  opacity: 1;
  transition: opacity 0.15s, z-index 0s;
  position: fixed;
  width: 100%;
  overflow: auto;
  height: 100%;
}

div.mfhidden {
  opacity: 0;
  z-index: -1000;
  transition: all 0.15s;
}

div.fullhidden {
  display: none;
}

div.textout {
  opacity: 0;
  transition: all 0.2s;
}

div.mfhidden400 {
  opacity: 0;
  z-index: -1000;
  transition: all 0.4s;
}

.qualquestion {
  opacity: 1;
  transition: all 0.4s;
}

.CodeMirror.cm-s-material.CodeMirror-wrap {
  height: calc(var(--vh) - 225px);
  font-size: 18px;
}

/* Pre-render the bigger shadow, but hide it */
.make-it-fast::after {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  animation: pulse-red 0.3s ease-in-out infinite;
}

/* Transition to showing the bigger shadow on hover */
.make-it-fast:hover::after {
  opacity: 1;
}

@keyframes pulse-red {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes growit {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.pulsateit {
  animation: pulsate 2s ease-in-out 0s 5 forwards;
  border: 1px solid var(--toolbar-background);
}

@keyframes growbig {
  0% {
    opacity: 0;
    transform: scale(0) translate(-50%, -50%);
  }

  50% {
    opacity: 1;
    transform: scale(2) translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: scale(4) translate(-50%, -50%);
  }
}

@keyframes helpglow {
  0% {
    transform: scale(0.8);
  }

  20% {
    transform: scale(0.9);
  }

  40% {
    transform: scale(0.8);
  }

  60% {
    transform: scale(0.9);
  }

  80% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes helpborder {
  0% {
    color: var(--toolbar-background);
  }

  20% {
    color: grey;
  }

  40% {
    color: var(--toolbar-background);
  }

  60% {
    color: grey;
  }

  80% {
    color: var(--toolbar-background);
  }

  100% {
    color: grey;
  }
}

.helparrow {
  color: var(--toolbar-background) !important;
}

.helpglow {
  color: white !important;
  background: var(--toolbar-background) !important;
  animation: helpglow 5s ease-in 0s 1;
  font-size: 15px !important;
  font-family: var(--font-normal) !important;
  margin-top: -5px;
  transform: translateX(8px);
}

.biggrow {
  padding: 15px;
  font-family: var(--font-bold);
  text-align: center;
  display: none;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: left top;
  height: 50px;
  color: white;
  border: 1px solid var(--toolbar-background);
  background: var(--toolbar-background);
  border-radius: 5px;
  pointer-events: none;
}

.biggrow.animate {
  transform-origin: left top;
  display: block;
  animation: growbig 3.5s ease-in 0s 1;
}

.reviewtext {
  font-family: var(--font-medium);
  font-variant: small-caps;
  font-size: 8px;
}

.mainframe {
  overflow: auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 52px;
  background: white;
  padding: 20px;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.15s, z-index 0s 0.15s;
}

.mainframe.book {
  background: #e5e5e5;
  top: 50px;
}

.mainframe.user {
  background: #033;
  top: 0px;
}

.mainframe.white {
  background: white;
  top: 0px;
}

.mainframe.app {
  max-width: 812px;
}

@media (min-width: 813px) {
  .mainframe.app {
    margin: 25px auto;
  }
}

.termbold {
  color: var(--toolbar-background);
}

#payment .bold {
  color: var(--toolbar-background);
}

.helpid .bold {
  color: var(--toolbar-background);
}

.ititle .bold {
  font-style: italic;
}

.ilesson .bold {
  color: var(--toolbar-background);
}

.itext .bold {
  font-style: italic;
}

.summary .bold {
  font-style: italic;
}

.iconText {
  fill: #fff;
  font-size: 0.75rem;
}

.custom-tooltip {
  border: 1px solid grey;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4px;
}

.custom-label {
  font-family: var(--font-normal);
  font-size: 11px;
}

#country option {
  margin-left: 5px;
  font-family: var(--font-normal);
}

.grecaptcha-badge {
  visibility: hidden;
}

.doneWord {
  background: #77cc77;
}

.activeWord {
}

.chipnumber {
  display: inline-block;
  padding-right: 10px;
  font-size: 13px;
  width: 35px;
}

.chipcross {
  padding-left: 10px;
}

.List {
  overflow-y: visible !important;
}

.xpos {
  padding-left: 3px;
  font-size: 14px;
  color: grey;
}



#flexwrap .ui.pointing.secondary.menu {
  margin-bottom: 0px;
}