.sd-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: calcSize(6);
  background-position: right calcSize(1.5) top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: calcSize(3) calcSize(3);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 15L17 10H7L12 15Z' fill='%23909090'/%3E%3C/svg%3E%0A");
  opacity: 1;
}
.sd-dropdown--empty:not(.sd-input--disabled) {
  color: $foreground-light;
}
.sd-dropdown option {
  color: $foreground;
  font-family: $font-family;
  font-size: calcSize(2);
}
