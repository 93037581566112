.sd-matrix {
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }
}

.sd-matrix__label {
  display: flex;
  justify-content: center;
}
.sd-matrix__text {
  padding: calcSize(2);
}
.sd-matrix__text--checked {
  background-color: $primary-light;
  padding: calcSize(2);
}
.sd-matrix__cell:first-child {
  font-weight: 600;
  text-align: left;
}