.sd-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: static;
  width: 100%;
  height: calcSize(6);
  box-sizing: border-box;
  padding: calcSize(1.5) calcSize(2);
  line-height: calcSize(3);
  font-family: $font-family;
  font-size: calcSize(2);
  color: $foreground;
  background-color: $background-for-editors;
  box-shadow: inset 0px 1px 2px $shadow-inner;
  border: none;
  border-radius: 3px;
  text-align: left;
}
.sd-input.sd-input:focus {
  outline: none;
}
.sd-input--disabled {
  background-color: $background-for-editors;
}
.sd-input::placeholder {
  color: $foreground-light;
}
.sd-input--disabled,
.sd-input--disabled::placeholder {
  color: $foreground-disabled-for-editors;
}
.sd-input:focus {
  box-shadow: 0 0 0 2px $primary, inset 0px 1px 2px $border-inside;
}
.sd-input--error {
  background-color: $red-light;
}
