.ql-container {
  height: calc(100% - 42px);
  overflow: auto;
}

.quill {
  overflow: auto;
  height: 100%;
}

.quill p {
  margin-top: 8px;
}
