.sd-checkbox__decorator {
  border-radius: 3px;
}
.sd-checkbox__svg {
  display: none;
  width: 20px;
  height: 20px;
}

.sd-checkbox--checked .sd-checkbox__svg {
  display: block;
  use {
    fill: $background;
  }
}

.sd-checkbox--checked.sd-checkbox--disabled .sd-checkbox__svg use {
  fill: $border;
}
.sd-checkbox--checked .sd-checkbox__control:focus + .sd-checkbox__decorator .sd-checkbox__svg use {
  fill: $primary;
}
