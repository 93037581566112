.sd-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  margin-top: calcSize(2);
}
.sd-page > .sd-row--multiple,
.svc-row > .sd-row--multiple {
  background: $background;
  box-shadow: 0px 1px 2px $shadow-inner;
  border-radius: calcSize(0.5);
}
.sd-page > .sd-row--multiple {
  padding: 0 var(--sd-base-padding) var(--sd-base-padding);
}
.sd-row--multiple {
  gap: 16px;
  margin-top: calcSize(2);
  .sd-question {
    padding-right: 0 !important;
  }
  .sv-vue-row-additional-div {
    display: flex;
    flex-wrap: initial;
    flex-direction: row;
    width: 100%;
    gap: 16px;
  }
}
.svc-row .sd-row--multiple {
  overflow: auto;
  margin-top: calcSize(2);
}
.svc-row--multiple {
  .sd-row--multiple {
    gap: 8px;
  }
}
:not(.svc-row--ghost) > .sd-row {
  min-height: 50px;
}

.sd-row__panel {
  box-sizing: border-box;
  width: 100%;
}

.sd-row__question {
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
}
